<template>
  <div class="report-instance-form-container">
    <page-header :title="__('Generate Report')" />
    <el-form
      ref="reportInstanceForm"
      label-position="top"
      label-width="100px"
      class="form-container"
      :model="report"
      :rules="rules"
    >
      <el-row class="create-template-row">
        <el-form-item
          class="is-required"
          :label="__('Report Template')"
          prop="report.id"
        >
          <p v-if="!differentUser" class="additional-info">
            <!-- eslint-disable-next-line -->
            {{ __("Reports are generated based on a Report Template. If you do not have a template for this report you will need to create one first.") }}
            <span class="create-template-btn" @click="$emit('create-template')"
              >{{ __("Create Report Template") }}.</span
            >
          </p>

          <el-select
            :disabled="differentUser"
            filterable
            v-model="report.report.id"
            default-first-option
            clearable
            :placeholder="__('Select report template')"
          >
            <el-option
              v-for="report in reportsToShow"
              :key="report.report_id"
              :value="report.report_id"
              :label="report.report_name"
              :disabled="report.disabled || false"
            >
              <div>
                {{ report.report_name }}
                <span
                  style="font-size: 0.8rem;color:#F84856"
                  v-if="report.disabled"
                >
                  [{{ __("deleted from account") }}]</span
                >
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-form-item
        required
        :label="__('Report name')"
        prop="report_instance_name"
      >
        <el-input
          :disabled="differentUser"
          v-model="report.report_instance_name"
          :placeholder="__('Enter report name')"
          maxlength="200"
          show-word-limit
          v-loading="isChecking"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="__('Report description')"
        prop="report_instance_description"
      >
        <el-input
          :disabled="differentUser"
          v-model="report.report_instance_description"
          :placeholder="__('Enter report description')"
        ></el-input>
      </el-form-item>

      <el-form-item required :label="__('Task')" prop="task_id">
        <el-select
          :disabled="differentUser"
          :placeholder="__('Select task')"
          default-first-option
          filterable
          v-model="report.task_id"
        >
          <el-option :value="0" :label="__('All')"></el-option>
          <el-option
            v-for="task in tasks"
            :value="task.task_id"
            :key="'_task_' + task.task_id"
            :label="getTaskLabel(task)"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item required :label="__('Date selection')" prop="date_range">
        <el-date-picker
          :disabled="differentUser"
          value-format="yyyy-MM-dd"
          v-model="dateRange"
          type="daterange"
          align="right"
          unlink-panels
          :range-separator="__('To')"
          :start-placeholder="__('Start date')"
          :end-placeholder="__('End date')"
          style="width: 100%"
          :format="displayDateFormat"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <div
          v-if="!differentUser"
          class="additional-info"
          style="margin-top: 5px"
        >
          <span style="font-weight: bold;font-size:0.9rem">
            <!-- eslint-disable-next-line -->
            {{__("Your current timezone is set to :timezone. This can be changed in user profile", { timezone: timezone }) }}
          </span>
        </div>
      </el-form-item>
      <el-form-item v-if="!differentUser" :label="__('Notification email')">
        <div class="additional-info">
          <!-- eslint-disable-next-line -->
          {{ __("Depending on the size of the data source reports may take some time to run. To receive a notification to your email address once the report is ready, select the option below.") }}
        </div>
        <el-checkbox
          v-model="sendNotification"
          style="padding-left: 3px; padding-top: 5px"
        >
          {{ __("Send notification email") }}
        </el-checkbox>
      </el-form-item>
    </el-form>
    <template>
      <el-button
        v-if="!differentUser"
        type="primary"
        class="submitBtn"
        @click="handleSave"
        >{{ saveLabel }}</el-button
      >
      <el-button @click="handleCancel" class="cancelBtn">{{
        __("Cancel")
      }}</el-button>
    </template>
  </div>
</template>
<script>
import PageHeader from "@/components/PageHeader";
import { mapGetters } from "vuex";
import _ from "lodash";
import TimezoneMixin from "@/views/analyse/reports/mixins/TimezoneMixin";
import { validateReportInstanceName } from "@/api/reports";

export default {
  name: "ReportInstanceForm",
  components: { PageHeader },
  mixins: [TimezoneMixin],
  props: {
    reportInstance: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("reports", {
      reports: "getReports"
    }),

    ...mapGetters("tasks", {
      tasks: "getTasks"
    }),

    getTaskLabel() {
      return task => {
        return task.task_name + " (" + task.task_id + ")";
      };
    },

    dateRange: {
      get() {
        if (_.isArray(this.report.date_range)) {
          return this.report.date_range;
        }
        let startDate = _.get(this.report.date_range, "start_date");
        let endDate = _.get(this.report.date_range, "end_date");
        if (!startDate || !endDate) {
          return [];
        }
        return [startDate, endDate];
      },
      set(val) {
        this.report.date_range = val;
      }
    },

    reportsToShow() {
      if (
        this.report.report.disabled &&
        !_.some(
          this.reports,
          report => this.reportInstance.report.id === report.report_id
        )
      ) {
        let report = {
          report_id: this.reportInstance.report.id,
          report_name: this.reportInstance.report.name,
          ac_id: this.reportInstance.report.ac_id,
          disabled: true
        };
        return _.concat(report, this.reports);
      }
      return this.reports;
    },

    saveLabel() {
      return __("Generate Report");
    },

    report: {
      get() {
        return this.$data.$reportInstance;
      },
      set(val) {
        this.$data.$reportInstance = val;
      }
    },

    sendNotification: {
      get() {
        return !!this.report.send_notification;
      },
      set(val) {
        this.$data.$reportInstance.send_notification = val ? 1 : 0;
      }
    },

    differentUser() {
      return (
        this.report.requested_by.email &&
        this.report.requested_by.email !== this.$auth.user().email
      );
    }
  },
  data() {
    let validateInstanceName = async (rule, value, callback) => {
      try {
        if (value !== this.reportInstance.report_instance_name) {
          this.isChecking = true;
          let res = await validateReportInstanceName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Report Instance Name already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        this.isChecking = false;
      }
    };
    return {
      $reportInstance: {},
      rules: {
        "report.id": [
          {
            required: true,
            trigger: "blur",
            message: __("Report template is required")
          }
        ],
        report_instance_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Report name is required")
          },
          {
            validator: validateInstanceName,
            trigger: "blur"
          }
        ],
        task_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Task is required")
          }
        ],
        date_range: [
          {
            required: true,
            trigger: "blur",
            message: __("Date range is required")
          }
        ]
      },
      isChecking: false
    };
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    handleSave() {
      if (this.differentUser) {
        this.$message({
          type: "error",
          message: __("Only the user who created the report can request again")
        });
        return;
      }

      if (
        this.reportInstance.initiator === "system" &&
        !_.isEqual(this.reportInstance, this.report)
      ) {
        this.$message({
          type: "error",
          message: __(
            "System issued report jobs can only be rerun without any modifications"
          )
        });
        return;
      }

      this.$refs.reportInstanceForm.validate(valid => {
        if (valid) {
          this.$emit("save", this.report);
        }
      });
    }
  },
  watch: {
    reportInstance: {
      immediate: true,
      handler(val) {
        this.report = _.cloneDeep(val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.report-instance-form-container {
  padding-top: 25px;

  .form-container {
    .additional-info {
      line-height: 1.5;
      margin-block: 0;
    }

    .create-template-row {
      font-size: 0.8rem;

      .create-template-title {
        font-size: 1rem;
      }

      .create-template-btn {
        cursor: pointer;
        color: mediumblue;
        font-size: 0.825rem;
      }

      ::v-deep .el-select {
        margin-top: 10px;
      }
    }
  }
}
</style>
