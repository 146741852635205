<template>
  <div v-if="formAction" style="padding: 20px">
    <template v-if="isReport">
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentForm.report_name }}</div>
      <div class="titles">{{ __("Description") }}</div>
      <div class="details">{{ reportDescription }}</div>
      <div class="titles">{{ __("Chart Style") }}</div>
      <div class="details">{{ contentForm.chart_style }}</div>
      <div class="titles">{{ __("Datastore") }}</div>
      <div class="details">{{ datastoreName }}</div>

      <el-dropdown @command="handleAction" class="reportActionDropDown">
        <el-button type="primary">
          {{ __("Actions") }}<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(command, index) in commands"
            :key="index"
            :command="command.command"
            :disabled="!canWrite()"
            v-show="command.show"
          >
            <div
              style="display: flex; justify-content: flex-start; align-items: center"
            >
              <img
                v-if="command.icon"
                :src="getIconSVG(command.icon)"
                alt
                height="18px"
                width="18px"
                style="margin-right: 10px"
              />
              <span>{{ command.label }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </template>
    <template v-else>
      <report-instance-info
        :form-action="formAction"
        :report-instance="content"
        @preview-closed="handleCancel"
        @delete="handleCancel"
        @edit="handleEdit"
        @retry="submitReportInstanceForm"
      />
    </template>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModalDialog"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
    >
      <div v-if="isReport" style="max-width: 100%;">
        <responsive-container :lg="12" :md="12" :xl="12">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            label-width="100px"
            class="form-container"
          >
            <el-row type="flex" style="padding-top: 20px">
              <el-col :span="18">
                <page-header :title="__('Report')" :contentId="id" />
              </el-col>
            </el-row>

            <el-row type="flex" style="padding-top: 20px">
              <el-col>
                <el-tabs
                  v-model="activeTab"
                  class="tabs"
                  style="font-size: 1.2em;"
                >
                  <el-tab-pane :label="__('Details')" name="details">
                    <el-form-item
                      prop="report_name"
                      :label="__('Name')"
                      class="form_label_top"
                    >
                      <el-input
                        v-model="contentForm.report_name"
                        v-loading="isChecking"
                        maxlength="200"
                        show-word-limit
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="__('Description')">
                      <el-input
                        v-model="contentForm.report_description"
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="__('Graph Type')" prop="chart_style">
                      <el-select
                        v-model="contentForm.chart_style"
                        default-first-option
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(chartStyle, index) in chartStyles"
                          :key="index"
                          :value="chartStyle"
                          >{{ chartStyle }}
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      :label="__('Datastore')"
                      prop="source_id"
                      class="with-tooltips"
                    >
                      <div class="tooltips">
                        <!-- eslint-disable-next-line -->
                        {{ __("Select which data will be used to build the report.") }}
                      </div>
                      <el-select
                        v-if="dataStores.length"
                        v-model="contentForm.source_id"
                        style="width: 100%;"
                        default-first-option
                        :no-data-text="
                          __('Couldn\'t find a Datastore in your account')
                        "
                        :loading="dataStoresLoading"
                        :placeholder="__('select a datastore')"
                        filterable
                        clearable
                        @change="selectDataStore"
                      >
                        <el-option
                          v-for="item in dataStores"
                          :label="dsLabel(item.display_name)"
                          :value="item.data_store_id"
                          :key="'_datastore_' + item.data_store_id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-tab-pane>
                  <el-tab-pane :label="__('Metrics')" name="metrics">
                    <el-form-item
                      :label="__('Metric')"
                      prop="y_axis_data_source_field"
                    >
                      <el-select
                        v-model="contentForm.y_axis_data_source_field"
                        v-loading="loading"
                        default-first-option
                        style="width: 100%"
                        v-if="
                          tableColumnsRemoveSysAttributes(contentForm.source_id)
                            .length
                        "
                        :disabled="
                          contentForm.y_axis_data_source_function === 'count'
                        "
                      >
                        <el-option
                          v-for="column in defaultColumns"
                          :key="'_y_axis_data_source_field_' + column.name"
                          :label="column.label"
                          :value="column.name"
                        ></el-option>
                        <el-option
                          v-for="column in tableColumnsRemoveSysAttributes(
                            contentForm.source_id
                          )"
                          :key="'_y_axis_data_source_field_' + column.col_name"
                          :label="seriesDataColumnName(column)"
                          :value="column.col_name"
                        >
                        </el-option>
                      </el-select>
                      <el-input
                        v-else
                        :value="__('select a datastore')"
                        disabled
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="__('Calculation')">
                      <el-select
                        v-model="contentForm.y_axis_data_source_function"
                        default-first-option
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(yAxisAvailableFunction,
                          index) in yAxisAvailableFunctions"
                          :key="index"
                          :label="yAxisAvailableFunction"
                          :value="yAxisAvailableFunction"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item :label="__('Label')" prop="y_axis_label">
                      <el-input v-model="contentForm.y_axis_label"></el-input>
                    </el-form-item>
                  </el-tab-pane>
                  <el-tab-pane :label="__('Breakdown')" name="breakdown">
                    <el-form-item
                      :label="__('Breakdown')"
                      class="with-tooltips"
                      prop="x_axis_data_store"
                    >
                      <div class="tooltips">
                        <!-- eslint-disable-next-line -->
                        {{ __("This is how the values are categorised. On bar graphs this is displayed on the X axis.") }}
                      </div>
                      <el-select
                        v-model="contentForm.x_axis_data_store"
                        default-first-option
                        style="width: 100%"
                        v-if="
                          tableColumnsRemoveSysAttributes(contentForm.source_id)
                            .length
                        "
                      >
                        <el-option
                          v-for="column in defaultColumnsForBreakdown(
                            contentForm.source_id
                          )"
                          :key="'_x_axis_data_store_' + column.name"
                          :label="column.label"
                          :value="column.name"
                        ></el-option>
                        <el-option
                          v-for="column in tableColumnsRemoveSysAttributes(
                            contentForm.source_id
                          )"
                          :key="'_x_axis_data_store_' + column.col_name"
                          :label="seriesDataColumnName(column)"
                          :value="column.col_name"
                        ></el-option>
                      </el-select>
                      <el-input
                        v-else
                        :value="__('select a datastore')"
                        disabled
                      ></el-input>
                    </el-form-item>

                    <el-form-item :label="__('Label')">
                      <el-input v-model="contentForm.x_axis_label"></el-input>
                    </el-form-item>
                  </el-tab-pane>
                  <el-tab-pane :label="__('Series')" name="series">
                    <el-form-item prop="data_fields.selected_series_type">
                      <el-radio-group
                        v-model="contentForm.data_fields.selected_series_type"
                        @change="selectSeriesType"
                      >
                        <el-radio label="form_field_value"
                          >{{ __("Form field value") }}
                        </el-radio>
                        <el-radio label="custom_series"
                          >{{ __("Custom series") }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <el-form-item
                      v-if="
                        contentForm.data_fields.selected_series_type ===
                          'custom_series'
                      "
                      :prop="'data_fields.series_data'"
                      :rules="rules.data_fields.series_data"
                    >
                      <div class="seriesTable">
                        <div>{{ __("Series") }}</div>

                        <el-table
                          class="list-table"
                          :data="contentForm.data_fields.series_data"
                          fit
                          style="width: 100%"
                        >
                          <el-table-column :label="__('Name') + '*'">
                            <template slot-scope="scope">
                              <el-input
                                :class="
                                  classes(
                                    contentForm.data_fields.series_data[
                                      scope.$index
                                    ],
                                    'series_name'
                                  )
                                "
                                :value="
                                  contentForm.data_fields.series_data[
                                    scope.$index
                                  ].series_name
                                "
                                @input="
                                  setSeriesData($event)(
                                    scope.$index,
                                    'series_name'
                                  )
                                "
                              ></el-input>
                            </template>
                          </el-table-column>

                          <el-table-column :label="__('Query Condition') + '*'">
                            <template slot-scope="scope">
                              <query-condition-input
                                @edit-input="
                                  openQueryConditionModal(scope.$index)
                                "
                                :class="
                                  classes(
                                    contentForm.data_fields.series_data[
                                      scope.$index
                                    ],
                                    'series_query_condition'
                                  )
                                "
                                :query-condition="
                                  contentForm.data_fields.series_data[
                                    scope.$index
                                  ].series_query_condition
                                "
                                v-model="
                                  contentForm.data_fields.series_data[
                                    scope.$index
                                  ].series_query_condition
                                "
                              ></query-condition-input>
                            </template>
                          </el-table-column>

                          <el-table-column :label="__('Stack Name')">
                            <template slot-scope="scope">
                              <el-input
                                :class="
                                  classes(
                                    contentForm.data_fields.series_data[
                                      scope.$index
                                    ],
                                    'series_stack_group_name'
                                  )
                                "
                                :value="
                                  contentForm.data_fields.series_data[
                                    scope.$index
                                  ].series_stack_group_name
                                "
                                @input="
                                  setSeriesData($event)(
                                    scope.$index,
                                    'series_stack_group_name'
                                  )
                                "
                              ></el-input>
                            </template>
                          </el-table-column>

                          <el-table-column :label="__('Color')">
                            <template slot-scope="scope">
                              <el-color-picker
                                :value="
                                  contentForm.data_fields.series_data[
                                    scope.$index
                                  ].series_color
                                "
                                @input="
                                  setSeriesData($event)(
                                    scope.$index,
                                    'series_color'
                                  )
                                "
                              ></el-color-picker>
                            </template>
                          </el-table-column>

                          <el-table-column
                            class-name="cell-item-pointer"
                            width="40px"
                          >
                            <template slot-scope="scope">
                              <span @click="removeStackRow(scope.$index)">
                                <i class="el-icon-circle-close"></i>
                              </span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-form-item>
                    <div
                      v-else-if="
                        contentForm.data_fields.selected_series_type ===
                          'form_field_value'
                      "
                    >
                      <el-form-item :label="__('Series data field')">
                        <el-select
                          :value="
                            contentForm.data_fields.series_data[0].series_name
                          "
                          @input="setSeriesData($event)(0, 'series_name')"
                          clearable
                          default-first-option
                          style="width: 100%"
                          v-if="
                            tableColumnsRemoveSysAttributes(
                              contentForm.source_id
                            ).length
                          "
                        >
                          <el-option
                            v-for="(column, index) in getSeriesColumns(
                              contentForm.source_id
                            )"
                            :key="column.col_name"
                            :label="seriesDataColumnName(column)"
                            :value="column.col_name"
                            :selected="index === 0"
                          ></el-option>
                        </el-select>
                        <el-input
                          v-else
                          :value="__('select a datastore')"
                          disabled
                        ></el-input>
                      </el-form-item>

                      <el-form-item :label="__('Query condition')">
                        <template>
                          <query-condition-builder
                            :fields="
                              columnsForReportQueryBuilder(
                                selectedDsCollectionStructure
                              )
                            "
                            :value="eb"
                            :fieldTypes="fieldTypes"
                            :operators="operators"
                            v-loading="loading"
                            @previewDataStore="previewResponse"
                          >
                          </query-condition-builder>
                        </template>
                      </el-form-item>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane :label="__('Filters')" name="filters">
                    <el-form-item :label="__('Date selection')">
                      <el-select
                        v-model="contentForm.snapshot_time_frame"
                        default-first-option
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(dateRangeSelection,
                          index) in dateRangeSelections"
                          :value="dateRangeSelection"
                          :key="'_date_range_selection_' + index"
                          :label="formatDataRangeLabel(dateRangeSelection)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item :label="__('Task')">
                      <el-select
                        v-model="contentForm.task_id"
                        filterable
                        default-first-option
                        style="width: 100%"
                        @visible-change="handleVisibleChange"
                      >
                        <el-input
                          size="small"
                          :placeholder="__('Search tasks')"
                          v-model="search"
                          @input="handleSearch"
                        >
                          <i
                            slot="prefix"
                            class="el-input__icon el-icon-search"
                          ></i
                        ></el-input>
                        <el-option :value="0" :label="__('All')"></el-option>
                        <el-option
                          v-for="task in filteredTasks"
                          :value="task.task_id"
                          :key="'_task_' + task.task_id"
                          :label="getTaskLabel(task)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>

            <el-row
              type="flex"
              v-if="showReportChart(id)"
              style="padding-top: 20px;"
            >
              <el-col :span="3"></el-col>
              <el-col :span="18">
                <div class="chart">
                  <report-chart
                    :report="contentForm"
                    :key="componentKey"
                    :title="chartTitle"
                  ></report-chart>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </responsive-container>
      </div>
      <div v-else style="max-width: 100%;">
        <responsive-container :lg="12" :md="12" :xl="12">
          <report-instance-form
            @create-template="gotoReportTemplateCreate"
            @cancel="handleCancel"
            @save="submitReportInstanceForm"
            :report-instance="contentForm"
          />
        </responsive-container>
      </div>
      <responsive-container slot="footer" :lg="12" :md="12" :xl="12">
        <div style="display: flex;margin-bottom: 20px">
          <template v-if="isReport">
            <el-button
              @click.prevent="previewChart"
              :disabled="isPreviewDisabled"
              >{{ showPreviewButtonText(showPreview) }}
            </el-button>
            <save-button
              type="primary"
              @click="submitReportTemplateForm"
              class="submitBtn"
              :primaryKey="id"
              v-show="this.canWrite()"
              variant="CreateUpdate"
            />
            <el-button @click="handleCancel" class="cancelBtn"
              >{{ __("Cancel") }}
            </el-button>
          </template>
        </div>
      </responsive-container>
    </el-dialog>

    <!--    preview dialog-->
    <el-dialog
      v-if="showPreviewDialog && isReport"
      :visible.sync="showPreviewDialog"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      destroy-on-close
      lock-scroll
      :title="__('Preview')"
      @close="showPreview = false"
    >
      <el-form>
        <div style="margin-bottom: 30px">
          Timezone set to <span style="font-size: 1.05rem">{{ timezone }}</span>
          <span v-if="timezoneSource">
            as set in your
            <template v-if="timezoneSource === TIMEZONE_SOURCE_USER_PROFILE">
              <router-link to="/manage/profile"
                >{{ timezoneSource }}
              </router-link>
            </template>
            <template v-else> {{ timezoneSource }} settings </template>
          </span>
        </div>
        <el-form-item :label="__('Date selection')">
          <el-date-picker
            value-format="yyyy-MM-dd"
            :format="displayDateFormat"
            v-model="dateRange"
            type="daterange"
            align="right"
            unlink-panels
            :range-separator="__('To')"
            :start-placeholder="__('Start date')"
            :end-placeholder="__('End date')"
            :picker-options="pickerOptions"
            style="width: 100%"
            @change="previewChart()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="__('Task')">
          <el-select
            v-model="contentFormTaskId"
            style="width: 100%"
            default-first-option
            @change="previewChart()"
          >
            <el-option :value="0" :label="__('All')"></el-option>
            <el-option
              v-for="task in tasks"
              :value="task.task_id"
              :key="'_task_' + task.task_id"
              :label="getTaskLabel(task)"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <report-chart
        :report="contentFormInfo"
        :title="chartTitle"
        :key="componentKey"
      ></report-chart>
    </el-dialog>

    <!--    Run Now dialog-->
    <el-dialog
      v-if="isShowPreviewDialog"
      :visible.sync="showRunNowDialog"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      destroy-on-close
      lock-scroll
      title="Report Instance Filter"
      @close="showPreview = false"
    >
      <el-form>
        <div style="margin-bottom: 30px">
          {{ __("Timezone set to") }}
          <span style="font-size: 1.05rem">{{ timezone }}</span>
          <span v-if="timezoneSource">
            {{ __("as set in your") }}
            <template v-if="timezoneSource === TIMEZONE_SOURCE_USER_PROFILE">
              <router-link to="/manage/profile"
                >{{ timezoneSource }}
              </router-link>
            </template>
            <template v-else> {{ timezoneSource }} {{ "settings" }} </template>
          </span>
          <span v-else
            >. {{ __("You can change your preference in your") }}
            <router-link to="/manage/profile"
              >{{ __("user profile") }}
            </router-link>
          </span>
        </div>
        <el-form-item :label="__('Date selection')">
          <el-date-picker
            value-format="yyyy-MM-dd"
            :format="displayDateFormat"
            v-model="report_instance_dateRange"
            type="daterange"
            align="right"
            unlink-panels
            :range-separator="__('To')"
            :start-placeholder="__('Start date')"
            :end-placeholder="__('End date')"
            :picker-options="pickerOptions"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="__('Task')">
          <el-select
            v-model="contentFormTaskId"
            style="width: 100%"
            default-first-option
            @change="previewChart()"
          >
            <el-option :value="0" :label="__('All')"></el-option>
            <el-option
              v-for="task in tasks"
              :value="task.task_id"
              :key="'_task_' + task.task_id"
              :label="getTaskLabel(task)"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click.prevent="reportInstance">
            {{ __("Create Report Instance") }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      v-if="showModal && isReport"
      :visible.sync="showModal"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      destroy-on-close
      lock-scroll
      :title="__('Data')"
      width="90%"
      top="5vh"
    >
      <preview-table
        :tableData="dsData"
        :tableColumns="selectedDsCollectionStructure"
        :is-system-log="isTaskLogDs(contentForm.source_id)"
        disable-navigation
        show-pagination
        :search-visible="false"
        :show-query-condition-builder-icon="false"
        :show-refresh-icon="false"
        :loading-table="loadingCollectionData"
        :sortable="false"
      >
        <el-form class="form-container" slot="paginationToolbar">
          <pagination-toolbar
            :content-api="getDataStoresCollection"
            :show-search="false"
            :show-slot="true"
            list-changed-event-name="documents-changed"
            @success="handleFetchSuccess"
          >
          </pagination-toolbar>
        </el-form>
      </preview-table>
    </el-dialog>

    <el-dialog
      v-if="isShowQueryBuilder"
      :visible.sync="showQueryBuilderModal"
      :close-on-click-modal="false"
      :show-close="true"
      append-to-body
      destroy-on-close
      lock-scroll
      :title="__('Query builder')"
    >
      <query-condition-builder-dialog
        :key="qcComponentKey"
        :selected-datastore-structure="selectedDsCollectionStructure"
        :query-condition="selectedQueryCondition"
        :data-store-id="contentForm.source_id"
        :task-id="contentForm.task_id"
        :time-frame="contentForm.snapshot_time_frame"
        @save-query-condition="handleSaveQueryBuilder"
        :selected-query-condition-id="qcComponentKey"
      >
      </query-condition-builder-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import { mapActions, mapGetters, mapState } from "vuex";
import { Conditions, Core } from "vue-renderless-expression-builder";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import QueryConditionBuilder from "@/components/queryConditionBuilder/QueryConditionBuilder";
import PreviewTable from "@/components/queryConditionBuilder/PreviewTable";
import ReportChart from "@/views/analyse/reports/components/reportChart";
import QueryConditionBuilderDialog from "@/views/analyse/reports/components/QueryConditionBuilderDialog";
import QueryConditionInput from "@/views/analyse/reports/components/queryConditionInput";
import DataStore from "@/mixins/DataStore";
import _ from "lodash";
import ReportInstanceInfo from "@/views/analyse/reports/components/ReportInstanceInfo";
import { presetDates } from "@/utils/time";
import ReportInstanceForm from "@/views/analyse/reports/components/ReportInstanceForm";
import ResponsiveContainer from "@/components/ResponsiveContainer";
import { TIMEZONE_SOURCE } from "@/constants/timezone";
import { validateReportTemplateName } from "@/api/reports";
import PaginationToolbar from "@/components/PaginationToolbar";
import ReportsUtil from "@/mixins/ReportsUtil";
import { SYSTEM_LOG_DS } from "@/constants/datastore";
import { SeriesRulesValidation } from "@/utils/formValidationRules";

const { defaultFieldTypes } = Conditions.Defaults;

export default {
  mixins: [BaseContent, BaseContentInfoPanel, DataStore, ReportsUtil],
  components: {
    PaginationToolbar,
    ResponsiveContainer,
    ReportInstanceForm,
    ReportInstanceInfo,
    PageHeader,
    SaveButton,
    ReportChart,
    QueryConditionBuilder,
    PreviewTable,
    QueryConditionBuilderDialog,
    QueryConditionInput
  },
  data() {
    let validateTemplateName = async (rule, value, callback) => {
      try {
        if (value !== this.contentFormInfo.report_name || this.id === -1) {
          this.isChecking = true;
          let res = await validateReportTemplateName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(new Error(__("Report Template Name already exists")));
          } else {
            callback();
          }
        }
      } catch (e) {
        this.isChecking = false;
      }
    };
    const validateSeriesRules = (rule, value, callback) => {
      let validationResult = SeriesRulesValidation(rule, value, this);
      validationResult
        ? callback()
        : callback(__("Series information incomplete"));
    };
    return {
      search: "",
      submitFormTemplate: false,
      TIMEZONE_SOURCE_ACCOUNT: TIMEZONE_SOURCE.ACCOUNT,
      TIMEZONE_SOURCE_USER_PROFILE: TIMEZONE_SOURCE.USER_PROFILE,
      commands: [
        {
          command: "edit",
          show: "this.canWrite()",
          icon: "campaign_actions/icon_pencil.svg",
          label: __("Edit Report Template")
        },
        {
          command: "duplicate",
          show: "this.canWrite()",
          icon: "campaign_actions/icon_reset.svg",
          label: "Duplicate Report Template"
        },
        {
          command: "delete-report",
          show: "this.canRead()",
          label: __("Delete Report Template"),
          icon: "campaign_actions/icon_trash_new.svg"
        }
      ],
      //form data
      componentKey: 0,
      isSubmitting: false,
      activeTab: "details",
      rules: {
        report_name: [
          { required: true, trigger: "blur", message: __("Name is required") },
          { validator: validateTemplateName, trigger: "blur" }
        ],
        chart_style: [
          {
            required: true,
            trigger: "change",
            message: __("Graph type field is required")
          }
        ],
        source_id: [
          {
            required: true,
            trigger: "change",
            message: __("Datastore is required")
          }
        ],
        y_axis_data_source_field: [
          {
            required: true,
            trigger: "change",
            message: __("Metric is required")
          }
        ],
        y_axis_data_source_function: [
          {
            required: true,
            trigger: "change",
            message: __("Calculation is required")
          }
        ],
        x_axis_data_store: [
          {
            required: true,
            trigger: "change",
            message: __("Breakdown is required")
          }
        ],
        data_fields: {
          selected_series_type: [
            {
              required: true,
              trigger: "change",
              message: __("Series type is required")
            }
          ],
          series_data: [{ validator: validateSeriesRules }]
        }
      },
      tabStructure: {
        details: ["report_name", "chart_style", "source_id"],
        metrics: ["y_axis_data_source_field", "y_axis_label"],
        breakdown: ["x_axis_data_store", "x_axis_label"],
        series: ["data_fields.selected_series_type"],
        filters: []
      },
      pickerDateFormat: "YYYY-MM-DD",
      noOfStacks: 1,
      customSeriesCount: 0,
      showPreviewDialog: false,
      showRunNowDialog: false,
      showPreview: false,
      isChecking: false,

      dateRangeSelections: [
        "today",
        "yesterday",
        "last_7_days",
        "this_week",
        "last_week",
        "this_month",
        "last_month",
        "last_3_months"
      ],
      //chart data
      chartStyles: [
        "Column",
        "Bar",
        "Line",
        "Spline",
        "Area",
        "AreaSpline",
        "Scatter",
        "Pie"
        // "Table"
      ],
      yAxisAvailableFunctions: ["count", "sum", "avg", "max", "min"],
      yAxisDataSourceFunction: "",
      yAxisDataSourceField: "",
      dateRange: [],
      requestingDataStoreData: false,
      previewDataStoreDataResponse: "",
      showModal: false,
      showQueryBuilderModal: false,
      qcComponentKey: 0,
      selectedSeriesIndex: null,
      selectedQueryCondition: null,
      initialized: false,
      report_instance_dateRange: [],
      debouncePreviewChart: null
    };
  },

  computed: {
    classes() {
      return (row, key) => {
        if (
          !row.series_color &&
          !row.series_name &&
          typeof row.series_query_condition !== "object" &&
          !row.series_stack_group_name
        ) {
          return "empty-row empty-val";
        }

        if (!row[key] && key !== "series_stack_group_name") {
          return "empty-val";
        } else {
          return "non-empty-val";
        }
      };
    },
    showPreviewButtonText() {
      return showPreview => {
        return showPreview ? __("Reload preview") : __("Preview");
      };
    },
    ...mapState("canvas", {
      showExpressionBuilder: state => state.showExpressionBuilder
    }),

    ...mapGetters("app", {
      displayDateFormat: "displayDateFormat",
      timezone: "timezone",
      timezoneSource: "timezoneSource"
    }),

    getTaskLabel() {
      return task => {
        return task.task_name + " (" + task.task_id + ")";
      };
    },

    reportDescription() {
      return this.contentForm.report_description === ""
        ? "-"
        : this.contentForm.report_description;
    },
    openModalDialog() {
      return (
        this.openModal &&
        (this.isReport ||
          (!this.isReport && !["view", "select"].includes(this.formAction)))
      );
    },
    getSeriesColumns() {
      return dataStoreId => {
        if (!this.isTaskLogDs(dataStoreId)) {
          return this.tableColumnsRemoveSysAttributes(dataStoreId);
        } else {
          return _.concat(
            [
              {
                col_name: "_task_id",
                label: "_task_id (reserved)",
                type: defaultFieldTypes.NUMBER
              }
            ],
            this.tableColumnsRemoveSysAttributes(dataStoreId)
          );
        }
      };
    },
    seriesDataColumnName() {
      return column => {
        return column.label ? column.label : column.col_name;
      };
    },
    showReportChart() {
      return id => {
        return id !== -1 || this.showPreview;
      };
    },
    isPreviewDisabled() {
      return (
        !this.contentForm.source_id ||
        !this.tableColumns(this.contentForm.source_id).length
      );
    },
    isShowPreviewDialog() {
      return this.showPreviewDialog && this.isReport;
    },
    isShowQueryBuilder() {
      return this.showQueryBuilderModal && this.isReport;
    },

    datastoreName: function() {
      if (this.contentForm.datastore !== undefined) {
        const datastore = this.contentForm.datastore;
        const result =
          datastore.is_log === 1 &&
          datastore.display_name.includes(SYSTEM_LOG_DS)
            ? "=== SYSTEM LOG ==="
            : datastore.display_name;
        return result;
      }
      return "";
    },

    report_instance_task_id: function() {
      return this.contentFormTaskId;
    },

    isReport() {
      return !_.has(this.content, "report_instance_id");
    },

    contentFormTaskId: {
      get() {
        if (this.isCustomConfigDialogOpen) {
          return this.contentFormInfo.task_id;
        }
        return this.contentForm.task_id;
      },
      set(val) {
        if (this.isCustomConfigDialogOpen) {
          this.contentFormInfo.task_id = val;
        } else {
          this.contentForm.task_id = val;
        }
      }
    },

    dsLabel() {
      return displayName => {
        if (displayName.includes("SYSTEM_LOG_DS")) {
          return "=== SYSTEM_LOG ===";
        } else {
          return displayName;
        }
      };
    },

    yAxisDataSource() {
      return this.yAxisDataSourceField + "|" + this.yAxisDataSourceFunction;
    },

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapState("reports", {
      loading: state => state.loading
    }),

    ...mapState("dataStores", {
      dataStoresLoading: state => state.loading
    }),

    ...mapGetters("dataStores", {
      tableColumns: "dataStoreCollectionStructure",
      tableColumnsRemoveSysAttributes:
        "dataStoreCollectionStructureRemoveSystemAttributes",
      dataStores: "datastoresExceptCallMonitorDS"
    }),

    ...mapState("tasks", {
      tasksLoading: state => state.loading,
      tasks: state => state.tasks
    }),

    isCustomConfigDialogOpen() {
      return this.showRunNowDialog || this.showPreviewDialog;
    },

    seriesData: {
      get() {
        return _.cloneDeep(this.contentForm.data_fields.series_data);
      }
    },

    pickerOptions() {
      let contentForm;
      if (this.isCustomConfigDialogOpen) {
        contentForm = this.contentFormInfo;
      } else {
        contentForm = this.contentFormInfo;
      }
      let self = this;
      let shortCuts = [
        {
          text: __("Today"),
          onClick(picker) {
            contentForm.snapshot_time_frame = "today";
            picker.$emit("pick", [
              presetDates(self.timezone).today,
              presetDates(self.timezone).today
            ]);
          }
        },
        {
          text: __("Yesterday"),
          onClick(picker) {
            contentForm.snapshot_time_frame = "yesterday";
            picker.$emit("pick", [
              presetDates(self.timezone).yesterday,
              presetDates(self.timezone).yesterday
            ]);
          }
        },
        {
          text: __("Last 7 days"),
          onClick(picker) {
            contentForm.snapshot_time_frame = "last_7_days";
            picker.$emit("pick", [
              presetDates(self.timezone).sevenDaysAgo,
              presetDates(self.timezone).yesterday
            ]);
          }
        },
        {
          text: __("This Week"),
          onClick(picker) {
            contentForm.snapshot_time_frame = "this_week";
            picker.$emit("pick", [
              presetDates(self.timezone).startOfThisWeek,
              presetDates(self.timezone).endOfThisWeek
            ]);
          }
        },
        {
          text: __("Last Week"),
          onClick(picker) {
            contentForm.snapshot_time_frame = "last_week";
            picker.$emit("pick", [
              presetDates(self.timezone).startOfLastWeek,
              presetDates(self.timezone).endOfLastWeek
            ]);
          }
        },
        {
          text: __("This month"),
          onClick(picker) {
            contentForm.snapshot_time_frame = "this_month";
            picker.$emit("pick", [
              presetDates(self.timezone).startOfThisMonth,
              presetDates(self.timezone).endOfThisMonth
            ]);
          }
        },
        {
          text: __("Last month"),
          onClick(picker) {
            contentForm.snapshot_time_frame = "last_month";
            picker.$emit("pick", [
              presetDates(self.timezone).startOfLastMonth,
              presetDates(self.timezone).endOfLastMonth
            ]);
          }
        },
        {
          text: __("Last 3 months"),
          onClick(picker) {
            contentForm.snapshot_time_frame = "last_3_months";
            picker.$emit("pick", [
              presetDates(self.timezone).startOfLastThreeMonth,
              presetDates(self.timezone).endOfLastMonth
            ]);
          }
        }
      ];

      if (this.showPreviewDialog === true || this.showRunNowDialog === true) {
        shortCuts.push({
          text: __("Custom range"),
          onClick() {
            contentForm.snapshot_time_frame = "custom_range";
          }
        });
      }

      return {
        shortcuts: shortCuts,
        onPick: () => {
          // contentForm.snapshot_time_frame = "custom_range";
        }
      };
    },

    chartTitle() {
      let titlePrefix = "";
      let contentForm = this.isCustomConfigDialogOpen
        ? this.contentFormInfo
        : this.contentForm;
      if (
        contentForm.snapshot_time_frame === "today" ||
        contentForm.snapshot_time_frame === "yesterday"
      ) {
        titlePrefix = "Daily ";
      } else if (
        contentForm.snapshot_time_frame === "this_month" ||
        contentForm.snapshot_time_frame === "last_month"
      ) {
        titlePrefix = "Monthly ";
      }
      return titlePrefix + this.contentForm.report_name;
    },

    filteredTasks() {
      if (!this.search || !Array.isArray(this.tasks)) {
        return this.tasks;
      }
      const searchTerm = this.search.toLowerCase();
      return this.tasks.filter(task =>
        this.getTaskLabel(task)
          .toLowerCase()
          .includes(searchTerm)
      );
    }
  },

  methods: {
    ...mapActions("reports", {
      createReport: "createReport",
      updateReport: "updateReport",
      reRunReportInstance: "reRunReportInstance",
      createReportInstance: "createReportInstance",
      deleteContentMethod: "deleteReport",
      undoDeleteContent: "undoDeleteReport",
      duplicateReportTemplate: "duplicateReportTemplate"
    }),

    ...mapActions("datastorecollection", {
      getDataStoresCollection: "getDataStoresCollection"
    }),

    handleSearch(val) {
      this.search = val;
    },

    handleVisibleChange(visible) {
      if (!visible) {
        this.search = "";
      }
    },

    debouncedPreviewChart(wait = 500) {
      if (this.debouncePreviewChart === null) {
        this.debouncePreviewChart = _.debounce(() => {
          this.previewChart();
        }, wait);
      }
      this.debouncePreviewChart();
    },

    setSeriesData(val) {
      return (index, prop) => {
        let sd = this.seriesData;
        sd[index][prop] = val;
        this.$set(this.contentForm.data_fields, "series_data", _.cloneDeep(sd));
      };
    },

    emitListChangedEvent() {
      if (this.isReport) {
        EventBus.$emit("report-list-changed");
      } else {
        EventBus.$emit("report-instances-list-changed");
      }
    },

    gotoReportTemplateCreate() {
      this.handleOpenModal(false);
      this.$nextTick(() => {
        EventBus.$emit("open-report-template-create");
      });
    },

    reportInstance() {
      let options = {
        date_range: this.report_instance_dateRange,
        task_id: this.report_instance_task_id
      };
      this.createReportInstance({
        report_id: this.content.report_id,
        options: options
      }).catch(err => {
        this.$message({
          type: "error",
          message: err.message || __("Operation Failed")
        });
      });

      this.showRunNowDialog = false;
    },

    getIconSVG(name) {
      return require("@/assets/icons/" + name);
    },

    handleAction(action) {
      if (action === "preview") {
        this.showPreviewDialog = true;
      } else if (action === "edit") {
        this.handleEdit();
      } else if (action === "run-report") {
        this.showRunNowDialog = true;
      } else if (action === "view-report") {
        //view report action
      } else if (action === "delete-report") {
        this.handleDelete(this.contentForm);
        // this.handleDelete();
      } else if (action === "duplicate") {
        this.handleDuplicate(this.contentForm);
      }
    },

    handleDuplicate(report) {
      this.duplicateReportTemplate(report)
        .then(data => {
          this.$message({
            message: data.message || __("Operation Successful"),
            type: "success"
          });

          EventBus.$emit("report-list-changed");
        })
        .catch(() => {
          this.$message({
            message: __("something went wrong"),
            type: "error"
          });
        });
    },

    initializeDateRange(contentForm) {
      this.$nextTick(() => {
        if (contentForm.snapshot_time_frame) {
          if (contentForm.snapshot_time_frame === "today") {
            this.$set(this.dateRange, 0, presetDates(this.timezone).today);
            this.$set(this.dateRange, 1, presetDates(this.timezone).today);
            this.$set(
              this.report_instance_dateRange,
              0,
              presetDates(this.timezone).today
            );
            this.$set(
              this.report_instance_dateRange,
              1,
              presetDates(this.timezone).today
            );
          } else if (contentForm.snapshot_time_frame === "yesterday") {
            this.$set(this.dateRange, 0, presetDates(this.timezone).yesterday);
            this.$set(this.dateRange, 1, presetDates(this.timezone).yesterday);
            this.$set(
              this.report_instance_dateRange,
              0,
              presetDates(this.timezone).yesterday
            );
            this.$set(
              this.report_instance_dateRange,
              1,
              presetDates(this.timezone).yesterday
            );
          } else if (contentForm.snapshot_time_frame === "this_month") {
            this.$set(
              this.dateRange,
              0,
              presetDates(this.timezone).startOfThisMonth
            );
            this.$set(
              this.dateRange,
              1,
              presetDates(this.timezone).endOfThisMonth
            );
            this.$set(
              this.report_instance_dateRange,
              0,
              presetDates(this.timezone).startOfThisMonth
            );
            this.$set(
              this.report_instance_dateRange,
              1,
              presetDates(this.timezone).endOfThisMonth
            );
          } else if (contentForm.snapshot_time_frame === "last_month") {
            this.$set(
              this.dateRange,
              0,
              presetDates(this.timezone).startOfLastMonth
            );
            this.$set(
              this.dateRange,
              1,
              presetDates(this.timezone).endOfLastMonth
            );
            this.$set(
              this.report_instance_dateRange,
              0,
              presetDates(this.timezone).startOfLastMonth
            );
            this.$set(
              this.report_instance_dateRange,
              1,
              presetDates(this.timezone).endOfLastMonth
            );
          } else if (contentForm.snapshot_time_frame === "last_3_months") {
            this.$set(
              this.dateRange,
              0,
              presetDates(this.timezone).startOfLastThreeMonth
            );
            this.$set(
              this.dateRange,
              1,
              presetDates(this.timezone).endOfLastMonth
            );
            this.$set(
              this.report_instance_dateRange,
              0,
              presetDates(this.timezone).startOfLastThreeMonth
            );
            this.$set(
              this.report_instance_dateRange,
              1,
              presetDates(this.timezone).endOfLastMonth
            );
          } else if (contentForm.snapshot_time_frame === "last_week") {
            this.$set(
              this.dateRange,
              0,
              presetDates(this.timezone).startOfLastWeek
            );
            this.$set(
              this.dateRange,
              1,
              presetDates(this.timezone).endOfLastWeek
            );
            this.$set(
              this.report_instance_dateRange,
              0,
              presetDates(this.timezone).startOfLastWeek
            );
            this.$set(
              this.report_instance_dateRange,
              1,
              presetDates(this.timezone).endOfLastWeek
            );
          } else if (contentForm.snapshot_time_frame === "this_week") {
            this.$set(
              this.dateRange,
              0,
              presetDates(this.timezone).startOfThisWeek
            );
            this.$set(
              this.dateRange,
              1,
              presetDates(this.timezone).endOfThisWeek
            );
            this.$set(
              this.report_instance_dateRange,
              0,
              presetDates(this.timezone).startOfThisWeek
            );
            this.$set(
              this.report_instance_dateRange,
              1,
              presetDates(this.timezone).endOfThisWeek
            );
          } else {
            // default to last_7_days
            this.$set(
              this.dateRange,
              0,
              presetDates(this.timezone).sevenDaysAgo
            );
            this.$set(this.dateRange, 1, presetDates(this.timezone).yesterday);
            this.$set(
              this.report_instance_dateRange,
              0,
              presetDates(this.timezone).sevenDaysAgo
            );
            this.$set(
              this.report_instance_dateRange,
              1,
              presetDates(this.timezone).yesterday
            );
          }
        } else {
          this.$set(this.dateRange, 0, presetDates(this.timezone).sevenDaysAgo);
          this.$set(this.dateRange, 1, presetDates(this.timezone).yesterday);
          this.$set(
            this.report_instance_dateRange,
            0,
            presetDates(this.timezone).sevenDaysAgo
          );
          this.$set(
            this.report_instance_dateRange,
            1,
            presetDates(this.timezone).yesterday
          );
        }
      });
    },

    removeStackRow(index) {
      this.contentForm.data_fields.series_data.splice(index, 1);
      this.$notify({
        title: __("Success"),
        message: __("stack row removed"),
        type: "success"
      });
    },

    setChartData(contentForm) {
      contentForm.ac_id = this.selectedAccountId;
      contentForm.y_axis_data_store = this.yAxisDataSource;
      contentForm.timestamp_column_name = contentForm.x_axis_data_store;

      if (
        contentForm.data_fields.selected_series_type === "form_field_value" &&
        this.initialized
      ) {
        const queryCondition = {
          query_condition: this.eb.root.toJSON(),
          order_by_column_name: {
            rowLimit: "no-limit",
            orderBy: { column: "", sortBy: "asc" }
          }
        };
        this.$set(
          contentForm.data_fields.series_data[0],
          "series_query_condition",
          queryCondition
        );
      }
    },

    submitReportInstanceForm(reportInstance) {
      this.isSubmitting = true;
      const process = reportInstance.report_instance_id
        ? this.reRunReportInstance
        : this.createReportInstance;

      process(reportInstance)
        .then(() => {
          this.$message({
            type: "success",
            message: __("Report added to queue successfully")
          });

          EventBus.$emit("report-instances-list-changed");
          this.handleCancel();
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.message || __("Operation Failed")
          });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

    submitReportTemplateForm() {
      this.submitFormTemplate = true;
      this.contentForm.data_fields.series_data = _.filter(
        this.contentForm.data_fields.series_data,
        series => {
          return !(
            series.series_query_condition === "" &&
            series.series_color === "" &&
            series.series_name === "" &&
            series.series_stack_group_name === ""
          );
        }
      );

      this.$refs.contentForm.validate((valid, errors) => {
        this.setChartData(this.contentForm);
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createReport : this.updateReport;
          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("Report template added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Report template updated successfully")
                  });

              EventBus.$emit("report-list-changed", data.data);
              this.handleCancel();
            })
            .catch(err => {
              // console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
          this.submitFormTemplate = false;
        } else {
          this.$message({
            type: "error",
            message: __("Please add required values before saving")
          });

          let errorPropName = Object.keys(errors);
          let findTab = _.findKey(this.tabStructure, function(structure) {
            return structure.some(r => errorPropName.includes(r));
          });

          if (!_.isEmpty(findTab)) {
            this.activeTab = findTab;
          }
          this.submitFormTemplate = false;
          return false;
        }
      });
    },

    previewChart() {
      if (
        this.contentForm.source_id &&
        this.tableColumns(this.contentForm.source_id).length
      ) {
        let contentForm = this.isCustomConfigDialogOpen
          ? this.contentFormInfo
          : this.contentForm;
        if (contentForm.snapshot_time_frame === "custom_range") {
          this.$set(contentForm, "start_date", this.dateRange[0]);
          this.$set(contentForm, "end_date", this.dateRange[1]);
        }
        this.componentKey++;
        this.setChartData(contentForm);
        this.showPreview = true;
      }
    },

    async previewResponse() {
      if (!this.contentForm.source_id) {
        this.$message({
          type: "error",
          message: __("select a datastore")
        });
        return false;
      }
      await this.buildRequestData(
        this.contentForm.source_id,
        this.eb.root.toJSON(),
        this.contentForm.task_id,
        this.contentForm.snapshot_time_frame
      );
      this.showModal = true;
      this.$nextTick(() => {
        EventBus.$emit("documents-changed", this.dsData, { page: 1 });
        this.debouncedPreviewChart();
      });
    },

    openQueryConditionModal(seriesIndex) {
      if (!this.contentForm.source_id) {
        this.$message({
          type: "error",
          message: __("select a datastore")
        });
        return false;
      }
      this.selectedSeriesIndex = seriesIndex;
      if (
        this.contentForm.data_fields.series_data[seriesIndex] &&
        !_.isEmpty(
          this.contentForm.data_fields.series_data[seriesIndex]
            .series_query_condition
        )
      ) {
        this.selectedQueryCondition = _.cloneDeep(
          this.contentForm.data_fields.series_data[seriesIndex]
            .series_query_condition.query_condition
        );
        this.qcComponentKey++;
      } else {
        this.selectedQueryCondition = null;
      }
      this.showQueryBuilderModal = true;
    },

    handleSaveQueryBuilder(eb) {
      this.showQueryBuilderModal = false;

      const queryCondition = {
        query_condition: eb.root,
        order_by_column_name: {
          rowLimit: "no-limit",
          orderBy: { column: "", sortBy: "asc" }
        }
      };
      this.$set(
        this.contentForm.data_fields.series_data[this.selectedSeriesIndex],
        "series_query_condition",
        queryCondition
      );

      this.selectedSeriesIndex = null;
      this.$nextTick(() => {
        this.debouncedPreviewChart();
      });
    },

    // new
    loadDataStore(data_store_id) {
      // this.selectedDsCollectionStructure.splice(
      //   0,
      //   this.selectedDsCollectionStructure.length
      // );
      this.selectedDsCollectionStructure = [];

      // condition factory needs at least one field
      this.selectedDsCollectionStructure.push({
        name: "_id",
        label: "_id (reserved)",
        type: "objectId",
        choices: null,
        secure: false
      });
      if (data_store_id) {
        if (this.isTaskLogDs(data_store_id)) {
          this.selectedDsCollectionStructure.push({
            name: "_uuid",
            label: "_uuid (reserved)",
            type: defaultFieldTypes.TEXT,
            choices: null,
            secure: false
          });
        }

        this.selectedDsCollectionStructure.push({
          name: "_timestamp",
          label: "_timestamp",
          type: "timestamp",
          choices: null,
          secure: false
        });
        this.selectedDsCollectionStructure.push({
          name: "_task_id",
          label: "_task_id (reserved)",
          type: defaultFieldTypes.NUMBER,
          choices: null,
          secure: false
        });

        const columns = this.tableColumns(data_store_id);
        if (columns && columns.length) {
          for (let i in columns) {
            const fieldType = this.fieldType(columns[i].type);
            this.selectedDsCollectionStructure.push({
              name: columns[i].col_name,
              label: columns[i].label ? columns[i].label : columns[i].col_name,
              type: fieldType,
              choices: null,
              secure: !!columns[i].secure
            });
          }
        }
        this.qcComponentKey++;
      }
    },

    loadSeries() {
      this.resetConditionValue();
      if (
        this.contentForm.data_fields &&
        this.contentForm.data_fields.series_data &&
        this.contentForm.data_fields.selected_series_type
      ) {
        const seriesData = [...this.contentForm.data_fields.series_data];
        if (
          this.contentForm.data_fields.selected_series_type ===
          "form_field_value"
        ) {
          if (
            seriesData[0].series_query_condition &&
            seriesData[0].series_query_condition.query_condition
          ) {
            const queryConditionParsed = _.cloneDeep(
              seriesData[0].series_query_condition.query_condition
            );

            this.eb = new Core.ExpressionBuilder(queryConditionParsed, err => {
              console.log(err);
            });
          }
        }
      }
    },

    resetReportDataFieldsSeriesData() {
      this.$set(this.contentForm.data_fields, "series_data", []);

      // let defaultSeriesName = "";
      if (
        this.contentForm.data_fields.selected_series_type ===
          "form_field_value" &&
        this.contentForm.source_id
      ) {
        // defaultSeriesName = this.tableColumns(this.contentForm.source_id)[0]
        //   .col_name;

        this.$set(this.contentForm.data_fields, "series_data", [
          {
            series_name: "",
            series_query_condition: "",
            series_stack_group_name: "",
            series_color: ""
          }
        ]);
      }
    },

    selectDataStore(data_store_id) {
      this.resetReportDataFieldsSeriesData();
      this.resetConditionValue();
      this.loadDataStore(data_store_id);
      this.$set(this.contentForm, "x_axis_data_store", "_timestamp");
      this.$set(this.contentForm, "y_axis_data_source_field", "_id");
    },

    selectSeriesType() {
      this.resetConditionValue();
      this.resetReportDataFieldsSeriesData();
    },

    initializeReport() {
      if (this.id !== -1) {
        this.isSubmitting = true;
        if (this.contentForm.source_id) {
          this.loadDataStore(this.contentForm.source_id);
          this.loadSeries();
        }
        this.initializeDateRange(this.contentForm);
        this.isSubmitting = false;
        this.componentKey++;
      } else {
        this.showPreview = false;
        this.resetConditionValue();
        this.resetReportDataFieldsSeriesData();
      }
      this.initialized = true;
    },

    formatDataRangeLabel(dateRangeSelection) {
      return __(_.startCase(dateRangeSelection));
    }
  },

  watch: {
    "contentForm.chart_style": function() {
      this.$nextTick(() => {
        if (this.isReport) {
          this.debouncedPreviewChart();
        }
      });
    },

    "contentForm.source_id": function(val) {
      if (!val) {
        this.showPreview = false;
      }
      this.$nextTick(() => {
        if (this.isReport) {
          this.debouncedPreviewChart();
        }
      });
    },

    "contentForm.data_fields": {
      deep: true,
      handler: function() {
        if (
          this.isReport &&
          this.contentForm.data_fields.selected_series_type === "custom_series"
        ) {
          if (
            !_.some(this.contentForm.data_fields.series_data, dataField =>
              _.isEmpty(dataField.series_name)
            )
          ) {
            if (!this.submitFormTemplate) {
              this.customSeriesCount += 1;
              this.contentForm.data_fields.series_data.push({
                series_name: "",
                series_query_condition: "",
                series_stack_group_name: "",
                series_color: ""
              });
            }
          }
        }
        // this.previewChart();
      }
    },

    "contentForm.data_fields.series_data": {
      deep: true,
      handler(val, oVal) {
        // we need to compare both without series_query_condition
        const newVal = _.map(val, v => {
          return {
            series_color: v.series_color,
            series_name: v.series_name,
            series_stack_group_name: v.series_stack_group_name
          };
        });

        const oldVal = _.map(oVal, o => {
          return {
            series_color: o.series_color,
            series_name: o.series_name,
            series_stack_group_name: o.series_stack_group_name
          };
        });

        if (!_.isEqual(newVal, oldVal)) {
          this.debouncedPreviewChart();
        }
      }
    },

    "contentForm.snapshot_time_frame": function() {
      this.$nextTick(() => {
        if (this.isReport) {
          this.initializeDateRange(this.contentForm);
          this.debouncedPreviewChart();
        }
      });
    },

    "contentForm.task_id": function() {
      this.$nextTick(() => {
        if (this.isReport) {
          this.debouncedPreviewChart();
        }
      });
    },

    "contentForm.y_axis_data_source_field": function() {
      this.$nextTick(() => {
        if (this.isReport) {
          this.debouncedPreviewChart();
        }
      });
    },

    "contentForm.y_axis_data_source_function": function(val) {
      this.$nextTick(() => {
        if (this.isReport) {
          if (val === "count") {
            this.$set(this.contentForm, "y_axis_data_source_field", "_id");
          } else {
            this.debouncedPreviewChart();
          }
        }
      });
    },

    "contentForm.x_axis_data_store": function() {
      this.$nextTick(() => {
        if (this.isReport) {
          this.debouncedPreviewChart();
        }
      });
    },

    openModal(val) {
      if (this.isReport) {
        this.activeTab = "details";
        if (val === true) {
          this.initializeReport();
        }
      }
    },

    showPreviewDialog(val) {
      if (this.isReport) {
        this.contentFormInfo = _.cloneDeep(this.contentForm);
        if (val) {
          this.initializeDateRange(this.contentFormInfo);
        } else {
          this.initializeDateRange(this.contentForm);
        }
      }
    },

    showRunNowDialog(val) {
      if (this.isReport) {
        this.contentFormInfo = _.cloneDeep(this.contentForm);
        if (val) {
          this.initializeDateRange(this.contentFormInfo);
        } else {
          this.initializeDateRange(this.contentForm);
        }
      }
    },

    "contentForm.report_id": {
      immediate: true,
      handler() {
        if (this.isReport) {
          if (this.id === -1) {
            this.selectDataStore(null);
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/button.scss";
@import "~@/styles/node_common.scss";
@import "~@/styles/element-variables.scss";
@import "~@/styles/expression-builder.scss";
::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.reportActionDropDown {
  margin-top: 30px;
}

.editContentItemModal {
  padding: 0;

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}

::v-deep .el-picker-panel {
  &.disable-free-picking {
    .el-picker-panel__body {
      pointer-events: none;
    }
  }

  .el-picker-panel__shortcut.custom-range-selected {
    color: $content-theme-hover-color;
  }
}

.seriesTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.seriesTable {
  ::v-deep .goto-variable-configure {
    flex: 1;
  }

  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner,
  ::v-deep .non-empty-val .el-input__inner,
  ::v-deep .non-empty-val .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

.seriesTable ::v-deep .el-table::before {
  background-color: white !important;
}

.seriesTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}
</style>
